import React from 'react'
import { getCanonical, getImageSize, getImage, getTeaser } from '../lib/articleUtils'
import { x } from '@xstyled/styled-components'
import Ad from '../app/components/Ad'
import { FORMATS } from '../app/components/AdFormats'
import { formatAgo } from '../lib/dateUtils'
import ImageOrNoImage from '../app/components/ui/Image'
import NoImage from '../../assets/images/general/no-image.png'
import StyledLink from '../app/components/ui/Link'

const Template = ({ articles, authorName, section, noImage, isConnected, sectionLabel, moreButton, relatedSections, withReadCount, adPath, mapping, visitorId, sessionId }) => {
  const clickSource = 'Sport Widget'
  return (
    <x.div display='grid' gridTemplateColumns={{ sm: '100%', lg: 'minmax(min-content, 560px) minmax(min-content, 282px) 300px' }} gap={{ lg: '40' }}>
      <x.div display='flex' flexDirection='column' position='relative' className='divider-right' p={{ sm: '0 0 20px 0', lg: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0' }} borderStyle='solid' borderColor='divider'>
        {articles[0] &&
          <x.article>
            <StyledLink article={articles[0]} to={getCanonical(articles[0])} text='secondary' visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource}>
              <ImageOrNoImage image={getImage(articles[0])} w='100%' mb={10} width={getImageSize('hero')} alt={getTeaser(articles[0])} shape='16x9' noImage={NoImage} />
              <x.h2 text='h2'>{getTeaser(articles[0])}</x.h2>
              <x.p text='meta' color='meta' my={8}>By {articles[0].author} | Published {formatAgo(articles[0].published, true)}</x.p>
              <x.p text='large'>{articles[0].abstract}</x.p>
            </StyledLink>
          </x.article>}
      </x.div>
      <x.div position='relative' p={{ sm: '20px 0', lg: '0' }} mb={{ sm: '20px', lg: '0' }} className='divider-right' borderWidth={{ sm: '0 0 1px 0', lg: '0' }} borderStyle='solid' borderColor='divider'>
        {articles[1] &&
          <x.article pb={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
            <StyledLink article={articles[1]} to={getCanonical(articles[1])} display='flex' spaceX={{ sm: '10px', wAdD: '0', article: '10px' }} flexDirection={{ sm: 'unset', wAdD: 'column-reverse', article: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource}>
              <x.div flex='1 1 0'>
                <x.h6 text='h6'>{getTeaser(articles[1])}</x.h6>
                <x.p text='meta' color='meta' mt={8}>By {articles[1].author} | Published {formatAgo(articles[1].published, true)}</x.p>
              </x.div>
              <x.div>
                <ImageOrNoImage image={getImage(articles[1])} width={getImageSize('normal')} alt={getTeaser(articles[1])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100px' }} />
              </x.div>
            </StyledLink>
          </x.article>}
        {articles[2] &&
          <x.article py={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
            <StyledLink article={articles[2]} to={getCanonical(articles[2])} display='flex' spaceX={{ sm: '10px', wAdD: '0', article: '10px' }} flexDirection={{ sm: 'unset', wAdD: 'column-reverse', article: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource}>
              <x.div flex='1 1 0'>
                <x.h6 text='h6'>{getTeaser(articles[2])}</x.h6>
                <x.p text='meta' color='meta' mt={8}>By {articles[2].author} | Published {formatAgo(articles[2].published, true)}</x.p>
              </x.div>
              <x.div>
                <ImageOrNoImage image={getImage(articles[2])} width={getImageSize('normal')} alt={getTeaser(articles[2])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100px' }} display={{ sm: 'block', lg: 'none' }} visibility={{ sm: 'visible', lg: 'hidden' }} />
              </x.div>
            </StyledLink>
          </x.article>}
        {articles[3] &&
          <x.article py={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
            <StyledLink article={articles[3]} to={getCanonical(articles[3])} display='flex' spaceX={{ sm: '10px', wAdD: '0', article: '10px' }} flexDirection={{ sm: 'unset', wAdD: 'column-reverse', article: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource}>
              <x.div flex='1 1 0'>
                <x.h6 text='h6'>{getTeaser(articles[3])}</x.h6>
                <x.p text='meta' color='meta' mt={8}>By {articles[3].author} | Published {formatAgo(articles[3].published, true)}</x.p>
              </x.div>
              <x.div>
                <ImageOrNoImage image={getImage(articles[3])} width={getImageSize('normal')} alt={getTeaser(articles[3])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100px' }} display={{ sm: 'block', lg: 'none' }} visibility={{ sm: 'visible', lg: 'hidden' }} />
              </x.div>
            </StyledLink>
          </x.article>}
        {articles[4] &&
          <x.article py={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
            <StyledLink article={articles[4]} to={getCanonical(articles[4])} display='flex' spaceX={{ sm: '10px', wAdD: '0', article: '10px' }} flexDirection={{ sm: 'unset', wAdD: 'column-reverse', article: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource}>
              <x.div flex='1 1 0'>
                <x.h6 text='h6'>{getTeaser(articles[4])}</x.h6>
                <x.p text='meta' color='meta' mt={8}>By {articles[4].author} | Published {formatAgo(articles[4].published, true)}</x.p>
              </x.div>
              <x.div>
                <ImageOrNoImage image={getImage(articles[4])} width={getImageSize('normal')} alt={getTeaser(articles[4])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100px' }} display={{ sm: 'block', lg: 'none' }} visibility={{ sm: 'visible', lg: 'hidden' }} />
              </x.div>
            </StyledLink>
          </x.article>}
        {articles[5] &&
          <x.article pt={20}>
            <StyledLink article={articles[5]} to={getCanonical(articles[5])} display='flex' spaceX={{ sm: '10px', wAdD: '0', article: '10px' }} flexDirection={{ sm: 'unset', wAdD: 'column-reverse', article: 'row' }} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource}>
              <x.div flex='1 1 0'>
                <x.h6 text='h6'>{getTeaser(articles[5])}</x.h6>
                <x.p text='meta' color='meta' mt={8}>By {articles[5].author} | Published {formatAgo(articles[5].published, true)}</x.p>
              </x.div>
              <x.div>
                <ImageOrNoImage image={getImage(articles[5])} width={getImageSize('normal')} alt={getTeaser(articles[5])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100px' }} display={{ sm: 'block', lg: 'none' }} visibility={{ sm: 'visible', lg: 'hidden' }} />
              </x.div>
            </StyledLink>
          </x.article>}
      </x.div>
      <x.aside>
        <x.div textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column' p={{ sm: '20px', lg: '0' }}>
          <Ad isConnected={isConnected} adPath={adPath} slotId='mpu600-topright' targeting={{ mpu600: 'topright' }} collapseEmptyDiv mapping={mapping} {...FORMATS.mpu600} />
        </x.div>
      </x.aside>
    </x.div>
  )
}

export default Template
