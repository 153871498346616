import React from 'react'
import { getCanonical, getImageSize, getImage, getTeaser } from '../lib/articleUtils'
import { x } from '@xstyled/styled-components'
import { formatAgo } from '../lib/dateUtils'
import ImageOrNoImage from '../app/components/ui/Image'
import NoImage from '../../assets/images/general/no-image.png'
import StyledLink from '../app/components/ui/Link'

const Template = ({ articles, authorName, section, noImage, isConnected, sectionLabel, moreButton, relatedSections, withReadCount, adPath, visitorId, sessionId }) => {
  const clickSource = 'Partnered Widget'
  return (
    <x.div display='grid' gridTemplateColumns={{ sm: '100%', lg: 'repeat(3, calc((100% - 80px) / 3))' }} gap={{ lg: '40' }}>
      {articles[0] &&
        <x.article position='relative' className='divider-right' p={{ sm: '20px 0', lg: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0' }} borderStyle='solid' borderColor='divider'>
          <StyledLink article={articles[0]} to={getCanonical(articles[0])} display='flex' spaceX={10} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource}>
            <x.div flex='1 1 0'>
              <x.h5 text='h5' my={8}>{getTeaser(articles[0])}</x.h5>
              <x.p text='meta' color='meta' my={8}>By {articles[0].author} | Published {formatAgo(articles[0].published, true)}</x.p>
              <x.span text='brand-badge' bg='rgba(47, 128, 237, 0.2);' p='5px 8px' borderRadius='100'>Partnered</x.span>
            </x.div>
            <x.div>
              <ImageOrNoImage image={getImage(articles[0])} width={getImageSize('130')} alt={getTeaser(articles[0])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100px' }} />
            </x.div>
          </StyledLink>
        </x.article>}
      {articles[1] &&
        <x.article position='relative' className='divider-right' p={{ sm: '20px 0', lg: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0' }} borderStyle='solid' borderColor='divider'>
          <StyledLink article={articles[1]} to={getCanonical(articles[1])} display='flex' spaceX={10} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource}>
            <x.div flex='1 1 0'>
              <x.h5 text='h5' mb={8}>{getTeaser(articles[1])}</x.h5>
              <x.p text='meta' color='meta' my={8}>By {articles[1].author} | Published {formatAgo(articles[1].published, true)}</x.p>
              <x.span text='brand-badge' bg='rgba(47, 128, 237, 0.2);' p='5px 8px' borderRadius='100'>Partnered</x.span>
            </x.div>
            <x.div>
              <ImageOrNoImage image={getImage(articles[1])} width={getImageSize('130')} alt={getTeaser(articles[1])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100px' }} />
            </x.div>
          </StyledLink>
        </x.article>}
      {articles[2] &&
        <x.article p={{ sm: '20px 0 0 0', lg: '0' }}>
          <StyledLink article={articles[2]} to={getCanonical(articles[2])} display='flex' spaceX={10} visitorId={visitorId} sessionId={sessionId} section='news' source={clickSource}>
            <x.div flex='1 1 0'>
              <x.h5 text='h5' mb={8}>{getTeaser(articles[2])}</x.h5>
              <x.p text='meta' color='meta' my={8}>By {articles[2].author} | Published {formatAgo(articles[2].published, true)}</x.p>
              <x.span text='brand-badge' bg='rgba(47, 128, 237, 0.2);' p='5px 8px' borderRadius='100'>Sponsored</x.span>
            </x.div>
            <x.div>
              <ImageOrNoImage image={getImage(articles[2])} width={getImageSize('130')} alt={getTeaser(articles[2])} shape='square' noImage={NoImage} w={{ sm: '50px', lg: '100px' }} />
            </x.div>
          </StyledLink>
        </x.article>}
    </x.div>
  )
}

const HomePageBrandstories = (props) => {
  return (
    <Template {...props} />
  )
}

export default HomePageBrandstories
