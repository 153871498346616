import React from 'react'
import styled, { x } from '@xstyled/styled-components'
import Link from './../components/ui/Link'
import { Carousel, useCarousel } from '../components/ui/Carousel'

import next from '../../../assets/images/icons/gallery-next.svg'
import prev from '../../../assets/images/icons/gallery-prev.svg'

import CA from '../../../assets/images/newspapers/cape-argus.svg'
import CT from '../../../assets/images/newspapers/cape-times.svg'
import DN from '../../../assets/images/newspapers/daily-news.svg'
import DV from '../../../assets/images/newspapers/daily-voice.svg'
import DFA from '../../../assets/images/newspapers/dfa.svg'
import IOS from '../../../assets/images/newspapers/independent-on-saturday.svg'
import ISN from '../../../assets/images/newspapers/isolezwe-ngesonto.svg'
import IS from '../../../assets/images/newspapers/isolezwe.svg'
import Post from '../../../assets/images/newspapers/post.svg'
import PN from '../../../assets/images/newspapers/pretoria-news.svg'
import SS from '../../../assets/images/newspapers/saturday-star.svg'
import SI from '../../../assets/images/newspapers/sunday-independent.svg'
import ST from '../../../assets/images/newspapers/sunday-tribune.svg'
import TM from '../../../assets/images/newspapers/the-mercury.svg'
import TS from '../../../assets/images/newspapers/the-star.svg'
import WA from '../../../assets/images/newspapers/weekend-argus.svg'

const Newspapers = styled.div`
  position: relative;
  .carousel-pagination {
    display: none;
    visibility: hidden;
  }
  a, img {
    display: block;
    margin: auto;
  }
`

export const RelatedArticles = ({ relatedArticles, visitorId, sessionId, section }) => {
  if (typeof window === 'undefined') {
    return null
  }
  const clickSource = 'Newspaper Slider'
  const carousel = useCarousel({
    slidesToShow: 8,
    slidesToSwipe: 8,
    mobileSlidesToShow: 2,
    mobileSlidesToSwipe: 2
  })
  return (
    <Newspapers>
      <x.div w='100%' mx='auto' my={{ sm: '20px', lg: '50px' }} mb={20} px={42} position='relative'>
        <x.button mr="xs" onClick={carousel.goPrev} background='transparent' position='absolute' left={0} top='50%' transform='translateY(-50%)' opacity={{ disabled: '.5' }}>
          <x.img src={prev} />
        </x.button>
        <x.button onClick={carousel.goNext} background='transparent' position='absolute' right={0} top='50%' transform='translateY(-50%)' opacity={{ disabled: '.5' }}>
          <x.img src={next} />
        </x.button>
        <Carousel {...carousel} display='flex' alignItems='center' gap={20}>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <Link to='/the-star' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
              <x.img src={TS} />
            </Link>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <Link to='/pretoria-news' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
              <x.img src={PN} />
            </Link>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <Link to='/capetimes' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
              <x.img src={CT} />
            </Link>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <Link to='/capeargus' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
              <x.img src={CA}/>
            </Link>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <Link to='/mercury' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
              <x.img src={TM} />
            </Link>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <Link to='/dailynews' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
              <x.img src={DN} />
            </Link>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <x.a href='https://www.dailyvoice.co.za/' target='_blank' rel="noopener noreferrer nofollow">
              <x.img src={DV} />
            </x.a>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <x.a href='https://www.isolezwelesixhosa.co.za/' target='_blank' rel="noopener noreferrer nofollow">
              <x.img src={ISN} />
            </x.a>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <x.a href='https://www.isolezwe.co.za/' target='_blank' rel="noopener noreferrer nofollow">
              <x.img src={IS} />
            </x.a>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <Link to='/thepost' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
              <x.img src={Post} />
            </Link>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <Link to='/sunday-tribune' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
              <x.img src={ST} />
            </Link>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <Link to='/sundayindependent' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
              <x.img src={SI} />
            </Link>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <Link to='/ios' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
              <x.img src={IOS} />
            </Link>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <x.a href='https://www.dfa.co.za/' target='_blank' rel="noopener noreferrer nofollow">
              <x.img src={DFA} />
            </x.a>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <Link to='/saturday-star' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
              <x.img src={SS} />
            </Link>
          </Carousel.Slide>
          <Carousel.Slide alignItems='center' display='flex' alignSelf='center' p='0 10px'>
            <Link to='/weekend-argus' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}>
              <x.img src={WA} />
            </Link>
          </Carousel.Slide>
        </Carousel>
      </x.div>
    </Newspapers>
  )
}

export default RelatedArticles
