import React from 'react'
import Helmet from 'react-helmet'

const HomePageMeta = (props) => {
  const schema = {
    '@context': 'https://schema.org',
    '@graph': [{
      '@type': 'WebPage',
      name: process.env.RAZZLE_SITE_TITLE,
      alternateName: process.env.RAZZLE_TITLE_KEY,
      url: process.env.RAZZLE_SITE_URL,
      description: process.env.RAZZLE_SITE_DESCRIPTION,
      '@id': process.env.RAZZLE_SITE_URL,
      publishingPrinciples: `${process.env.RAZZLE_SITE_URL}/press-code`,
      mainEntityOfPage: process.env.RAZZLE_SITE_URL
    }, {
      '@type': 'NewsMediaOrganization',
      '@id': `${process.env.RAZZLE_SITE_URL}/#publisher`,
      name: process.env.RAZZLE_SITE_TITLE,
      alternateName: process.env.RAZZLE_TITLE_KEY,
      url: process.env.RAZZLE_SITE_URL,
      logo: process.env.RAZZLE_SITE_URL + '/logo.jpg',
      publishingPrinciples: `${process.env.RAZZLE_SITE_URL}/press-code`,
      sameAs: [
        process.env.RAZZLE_SITE_FACEBOOK,
        process.env.RAZZLE_SITE_TWITTER,
        process.env.RAZZLE_SITE_INSTAGRAM,
        process.env.RAZZLE_SITE_TIK_TOK,
        process.env.RAZZLE_SITE_URL + process.env.RAZZLE_SITE_RSS,
        process.env.RAZZLE_SITE_YOUTUBE
      ]
    }, {
      '@type': 'SiteNavigationElement',
      name: 'IOL - Home',
      url: process.env.RAZZLE_SITE_URL,
      '@id': `${process.env.RAZZLE_SITE_URL}/#header`
    }]
  }
  return (
    <Helmet>
      {/* inline script elements */}
      <script type='application/ld+json' class='schemantra'>
        {`${JSON.stringify(schema)}`}
      </script>
    </Helmet>
  )
}
export default HomePageMeta
