import React, { Fragment } from 'react'
import { getCanonical, getImageSize, getImage, getTeaser } from '../lib/articleUtils'
import { x } from '@xstyled/styled-components'
import { formatAgo } from '../lib/dateUtils'
import ImageOrNoImage from '../app/components/ui/Image'
import NoImage from '../../assets/images/general/no-image.png'
import StyledLink from '../app/components/ui/Link'
import Ad from '../app/components/Ad'
import { FORMATS, MAPPINGS } from '../app/components/AdFormats'

export const Skeleton = (props) => {
  return (
    <x.div display={{ sm: '', lg: 'grid' }} animation='pulse' gridTemplateColumns={{ sm: '', lg: 'repeat(4, calc((100% - 120px) / 4))' }} gap={{ lg: '40' }}>
      {Array(4).fill().map((v, i) =>
        <x.article key={i} position='relative' className='divider-right' p={{ sm: '0 0 20px 0', lg: '0', lastType: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0', lastType: '0' }} borderStyle='solid' borderColor='divider' mb={{ sm: '20px', lg: '0', last: '0' }}>
          <ImageOrNoImage width={getImageSize('slider')} shape='16x9' noImage={NoImage} />
          <x.div>
            <x.div h={7} mb={5} bg='#F0F0F0' />
            <x.div h={7} mb={5} bg='#F0F0F0' />
            <x.div w='33%' h={7} bg='#F0F0F0' />
            <x.div my={8} h={3} bg='#F0F0F0' />
          </x.div>
        </x.article>
      )}
    </x.div>
  )
}

const Template = ({ articles, authorName, section, noImage, isConnected, sectionLabel, moreButton, relatedSections, withReadCount, adPath, visitorId, sessionId }) => {
  const clickSource = 'Lifestyle Widget'
  return (
    <x.div display='grid' gridTemplateColumns={{ sm: '100%', lg: 'repeat(4, calc((100% - 120px) / 4))' }} gap={{ lg: '40' }}>
      {articles.map((article, index) => (
        <Fragment key={index}>
          {index > 0 && index % 16 === 0 &&
            <x.div key={index} maxWidth={1220} mx='auto' my={50} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column' w='100%' gridColumn='span 4'>
              <Ad isConnected={isConnected} adPath={adPath} collapseEmptyDiv slotId='leaderboard-middle' targeting={{ leaderboard: 'middle' }} className='advert' mapping={MAPPINGS.Leaderboard} {...FORMATS.leaderboard} />
            </x.div>}
          <x.article
            key={article.uuid} position='relative' className='divider-right' p={{ sm: '0 0 20px 0', lg: '0', lastType: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0', lastType: '0' }} borderStyle='solid'
            borderColor='divider' mb={{ sm: '20px', lg: '0', last: '0' }}
          >
            <StyledLink article={article} to={'/' + getCanonical(article)} display='flex' alignItems='flex-start' spaceX={{ sm: '10px', lg: 'unset' }} flexDirection={{ sm: 'row', lg: 'column-reverse' }} justifyContent={{ sm: 'space-between', lg: 'unset' }} visitorId={visitorId} sessionId={sessionId} section='home' source={clickSource}>
              <x.div mt={{ lg: '10px' }}>
                <x.h5 text={{ sm: 'h6', lg: 'h5' }} mb={8}>{getTeaser(article)}</x.h5>
                <x.p text='meta' color='meta' my={8}>By {article.author} | Published {formatAgo(article.published, true)}</x.p>
              </x.div>
              <x.div position='relative' display='flex' justifyContent='center' alignItems='center'>
                <ImageOrNoImage image={getImage(article)} w='auto' width={getImageSize('slider')} alt={getTeaser(article)} shape='16x9' display={{ sm: 'none', lg: 'block' }} noImage={NoImage} />
                <ImageOrNoImage image={getImage(article)} maxWidth={50} width={getImageSize('slider')} alt={getTeaser(article)} shape='square' display={{ sm: 'block', lg: 'none' }} noImage={NoImage} />
              </x.div>
            </StyledLink>
          </x.article>
        </Fragment>
      ))}
      {moreButton}
    </x.div>
  )
}
const LifestyleArticles = (props) => {
  return (
    <Template {...props} />
  )
}

export default LifestyleArticles
